*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    scrollbar-width: thin;
    scrollbar-color: rgb(28, 28, 28) #000000;
}

.active{
    color:'#f6ae2d'!important; 
}
#cashfree-modal{
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    justify-content: center;
    align-items: center;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.6);
    overflow-y: scroll;
}
#cashfree-modal div{
    border-radius: 5px;
    border:2px solid black
}
.active{
    color: #f6ae2d;
}